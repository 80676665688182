/* eslint-disable array-callback-return */

import React from "react";
import { ListGroup, Row, Col, Image, Card } from "react-bootstrap";
import { FaCheckCircle, FaRegTimesCircle } from "react-icons/fa";
import logo from "../Resources/images/criteriaLogo_wordmark.png";
import progressCircle from "../Resources/images/progress-circle@3x.png";
import warningIcon from "../Resources/images/mobile_warning@3x.png";
import stopwatchIcon from "../Resources/images/stopwatch_1x.png";
import { getSubdomain } from "../utils/shared";
import {
  nonMobileTests,
  personalityTests,
  rcatTests
} from "../utils/Reference/SubTestDataMaps";
import { apiURL, talentInsightsURL } from "../utils/constants";

export const getApplications = (
  applications: any,
  itemNumber: number,
  backgroundColor: string,
  fontColor: string,
  userDisqualified: boolean
) => {
  const applicationsToReturn: any[] = [];
  if (applications) {
    applications.forEach((app: any) => {
      Object.keys(app).forEach((index: string) => {
        applicationsToReturn.push(
          <ListGroup.Item
            disabled={app[index].completed}
            key={index}
            style={{ backgroundColor: backgroundColor }}
            className="px-0"
          >
            <Row noGutters>
              <Col xl={1} lg={1} md={1} sm={1} xs={1} className="text-center">
                <div
                  className="overview-number"
                  style={{ border: `1px solid ${fontColor}` }}
                  aria-hidden="true"
                >
                  {itemNumber}
                </div>
              </Col>
              <Col xl={9} lg={9} md={9} sm={9} xs={9}>
                {app[index].appDetail.applicationName}
              </Col>
              {app[index].completed ? (
                <Col
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  aria-hidden="true"
                  className="text-right"
                >
                  {userDisqualified ? (
                    <FaRegTimesCircle className="application-error-message" />
                  ) : (
                    <FaCheckCircle className="text-success" />
                  )}
                </Col>
              ) : null}
            </Row>
          </ListGroup.Item>
        );
        itemNumber = itemNumber + 1;
      });
    });
  }
  return applicationsToReturn;
};

export const getVideoInterview = (itemNumber: number, testEventData: any) => {
  const backgroundColor = testEventData?.landingPage?.backgroundColor;
  const fontColor = testEventData?.landingPage?.textColor;
  const completedVideoInterview = testEventData?.completed?.videoInterview;
  const interviewToReturn = [
    <ListGroup.Item
      key="interview"
      style={{ backgroundColor: backgroundColor }}
      className="px-0"
    >
      <Row noGutters>
        <Col xl={1} lg={1} md={1} sm={1} xs={1} className="text-center">
          <div
            className="overview-number"
            style={{ border: `1px solid ${fontColor}` }}
            aria-hidden="true"
          >
            {itemNumber}
          </div>
        </Col>
        <Col xl={9} lg={9} md={9} sm={9} xs={9}>
          Video Interview
        </Col>
        {completedVideoInterview && (
          <Col
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
            aria-hidden="true"
            className="text-right"
          >
            <FaCheckCircle className="text-success" />
          </Col>
        )}
      </Row>
    </ListGroup.Item>
  ];
  return interviewToReturn;
};

export const getDocuments = (
  testEventData: any,
  itemNumber: number,
  backgroundColor: string,
  fontColor: string
) => {
  const documentsToReturn: any[] = [];
  if (testEventData.requireResume > 0) {
    documentsToReturn.push(
      <ListGroup.Item
        disabled={testEventData.resumeComplete}
        key="resume"
        style={{ backgroundColor: backgroundColor }}
        className="px-0"
      >
        <Row noGutters>
          <Col xl={1} lg={1} md={1} sm={1} xs={1} className="text-center">
            <div
              className="overview-number"
              style={{ border: `1px solid ${fontColor}` }}
              aria-hidden="true"
            >
              {itemNumber}
            </div>
          </Col>
          <Col xl={9} lg={9} md={9} sm={9} xs={9}>
            {testEventData.translatedText.resume}
          </Col>
          {testEventData.resumeComplete ? (
            <Col
              xl={2}
              lg={2}
              md={2}
              sm={2}
              xs={2}
              aria-hidden="true"
              className="text-right"
            >
              <FaCheckCircle className="text-success" />
            </Col>
          ) : null}
        </Row>
      </ListGroup.Item>
    );
    itemNumber = itemNumber + 1;
  }
  if (testEventData.documents) {
    testEventData.documents.files.forEach((doc: any) => {
      documentsToReturn.push(
        <ListGroup.Item
          disabled={doc.completed}
          key={itemNumber}
          style={{ backgroundColor: backgroundColor }}
          className="px-0"
        >
          <Row noGutters>
            <Col xl={1} lg={1} md={1} sm={1} xs={1} className="text-center">
              <div className="overview-number" aria-hidden="true">
                {itemNumber}
              </div>
            </Col>
            <Col xl={9} lg={9} md={9} sm={9} xs={9}>
              {doc.name}
            </Col>
            {doc.completed ? (
              <Col
                xl={2}
                lg={2}
                md={2}
                sm={2}
                xs={2}
                aria-hidden="true"
                className="text-right"
              >
                <FaCheckCircle className="text-success" />
              </Col>
            ) : null}
          </Row>
        </ListGroup.Item>
      );
      itemNumber = itemNumber + 1;
    });
  }
  return documentsToReturn;
};

export const getTests = (
  testEventData: any,
  itemNumber: number,
  isMobile: boolean,
  isResumingTest: boolean,
  expiredTime: number,
  backgroundColor: string,
  fontColor: string,
  completedSubTestIds: any,
  testIdsArray: string[],
  testIndex: number,
  testIdToResume: string | null,
  lastQuestionNumber: number
) => {
  let testsToReturn: any[] = [];
  let sortedTests: string[] = [];
  let nameToDisplay: string = "";
  Object.keys(testEventData.tests).forEach((testId: string) => {
    sortedTests = Object.keys(testEventData.tests).sort(
      (a, b) =>
        testEventData.tests[a].testOrder - testEventData.tests[b].testOrder
    );
  });

  const incompleteTests: any[] = [];

  if (isResumingTest) {
    sortedTests.map((testId: any) => {
      if (!testEventData.tests[testId].completed) {
        incompleteTests.push(testId);
      }
    });
  }

  const completedSubTestIdsFromApi = testEventData?.completed?.subTestIds ?? [];

  sortedTests.forEach(testId => {
    // TODO: Per PM the plan is to call all personality assessments “Workplace Behavio(u)r Assessment” in the future.
    // TODO: Once that is ready for dev we can remove the below logic for Illsutrait and have BE update the personalityAssessment field value.
    if (String(testId) === "132") {
      nameToDisplay =
        testEventData.locale === "AU"
          ? "Workplace Behaviour Assessment"
          : "Workplace Behavior Assessment";
    } else if (String(testId) === "188" || String(testId) === "189") {
      nameToDisplay = "Cognitive Ability Test";
    } else {
      switch (testEventData.tests[String(testId)].details.testType) {
        case "aptitude":
          nameToDisplay = testEventData.translatedText.aptitudeAssessment;
          break;
        case "personality":
          nameToDisplay = testEventData.translatedText.personalityAssessment;
          break;
        case "emotional":
          nameToDisplay = "Emotify";
          break;
        case "skills":
        case "risk":
        case undefined:
          nameToDisplay = testEventData.tests[testId].details.title;
          break;
        default:
          break;
      }
    }
    const timeFactor = testEventData.timeFactor
      ? Number(testEventData.timeFactor)
      : 1;
    const timeRemaining =
      testId === "131" // adaptive CCAT
        ? testEventData.tests[testId].details.timeAllowed -
          lastQuestionNumber *
            testEventData.tests[testId].details.questionTimeLimit -
          expiredTime
        : isTimeFactorAppliedTMTest(testEventData, testId)
        ? testEventData.tests[testId].details.timeAllowed * timeFactor -
          expiredTime
        : testEventData.tests[testId].details.timeAllowed - expiredTime;
    const timeAllowed = isTimeFactorAppliedTMTest(testEventData, testId)
      ? testEventData.tests[testId].details.timeAllowed * timeFactor
      : testEventData.tests[testId].details.timeAllowed;
    const timeDisplay = getTimeDisplay(
      testEventData.tests[String(testId)].details.testType,
      timeRemaining,
      timeAllowed,
      isResumingTest,
      incompleteTests,
      testId,
      testIdToResume,
      fontColor
    );

    testsToReturn.push(
      <ListGroup.Item
        as="li"
        active={false}
        key={testId}
        style={{ backgroundColor: backgroundColor }}
        className="px-0"
      >
        <Row noGutters>
          <Col xl={1} lg={1} md={1} sm={1} xs={1} className="text-center">
            <div
              className="overview-number"
              style={{ border: `1px solid ${fontColor}` }}
              aria-hidden="true"
            >
              {itemNumber}
            </div>
          </Col>
          <Col xl={7} lg={7} md={7} sm={7} xs={7}>
            {nameToDisplay}
            {isMobile && nonMobileTests.includes(testId) ? (
              <img
                src={warningIcon}
                alt="warning icon"
                height="16px"
                className="ml-3"
              />
            ) : null}
            {isResumingTest && testIdToResume === testId ? (
              <div className="text-hint">
                {testEventData && testEventData.translatedText
                  ? testEventData.translatedText.inProgress
                  : "In Progress"}
              </div>
            ) : null}
            {isMobile &&
            (nonMobileTests.includes(String(testId)) ||
              testEventData.tests[String(testId)].details.isTyping) ? (
              <div className="text-hint">
                {`This test must be taken on a desktop computer. Go to
                ${
                  getSubdomain() === "AU"
                    ? " au.ondemandassessment.com "
                    : " www.oda1.com "
                }
                and enter your Event ID to complete this test.`}
              </div>
            ) : null}
          </Col>

          {completedSubTestIds.includes(testId) ||
          !testIdsArray.includes(testId) ||
          completedSubTestIdsFromApi.includes(testId) ? (
            <Col
              xl={4}
              lg={4}
              md={4}
              sm={4}
              xs={4}
              aria-hidden="true"
              className="text-right"
            >
              <FaCheckCircle className="text-success" />
            </Col>
          ) : (
            <Col xl={4} lg={4} md={4} sm={4} xs={4} className="text-right">
              {timeDisplay}
            </Col>
          )}
        </Row>
      </ListGroup.Item>
    );
    itemNumber = itemNumber + 1;
  });
  return testsToReturn;
};

const getTimeDisplay = (
  testType: string,
  timeRemaining: number,
  timeAllowed: number,
  isResumingTest: boolean,
  incompleteTests: string[],
  testId: string,
  testIdToResume: string | null,
  fontColor: string
) => {
  // testType is undefined if it is a TestMaker Test
  if (
    testType === "aptitude" ||
    testType === "skills" ||
    testType === "emotional" ||
    testType === undefined
  ) {
    // These should probably be redesigned to show consistent UoM
    if (isResumingTest && testIdToResume && testIdToResume === testId) {
      return (
        <React.Fragment>
          <img src={progressCircle} height="16px" className="mr-1" alt="" />
          <span className="overview-time">
            {Math.round(timeRemaining / 60)}m
            <span className="sr-only">inutes</span>
          </span>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <img src={stopwatchIcon} height="16px" className="mr-1" alt="" />
        <span className="overview-time" style={{ color: fontColor }}>
          {Number.isInteger(timeAllowed / 60)
            ? timeAllowed / 60
            : (timeAllowed / 60).toFixed(1)}{" "}
          m<span className="sr-only">inutes</span>
        </span>
      </React.Fragment>
    );
  } else if (testType === "personality" || testType === "risk") {
    return (
      <span className="overview-time" style={{ color: fontColor }}>
        approx. {Math.round(timeAllowed / 60)} min
      </span>
    );
  }
};

export const generateNewToken = async (
  testEventId: number | null,
  eventId: string,
  testTakerId: string | boolean
) => {
  // reset the token to ensure that it does not expire in the middle of the assessment
  try {
    const formData = {
      testEventId: testEventId,
      eventId: eventId,
      testTakerId: testTakerId
    };
    const response = await fetch(`${apiURL}/generateToken`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(formData)
    });
    const responseData = await response.json();
    if (response.ok) {
      return responseData.token;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const setRestartCounter = async (
  testIdsArray: string[],
  testIndex: number,
  testEventId: number | null,
  token: string,
  isVideoInterviewEvent?: boolean
) => {
  try {
    const noNeedToTrackRestart =
      personalityTests.includes(testIdsArray[testIndex]) ||
      isVideoInterviewEvent ||
      rcatTests.includes(testIdsArray[testIndex]);

    const response = await fetch(
      `${apiURL}/startEvent/${testEventId}${noNeedToTrackRestart ? "/1" : ""}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token
        }
      }
    );
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.log("restart counter error:", error);
  }
};

export const getDropdownOptions = (options: any) => {
  const dropdownOptions = options.map((option: any) => {
    const key = String(Object.keys(option)[0]);
    return (
      <option key={key} value={key}>
        {option[key]}
      </option>
    );
  });
  return dropdownOptions;
};

export const isElevenDigit = (userInputTrimmed: string) => {
  const length = userInputTrimmed.length;
  //if userInput length is longer than 11 characters, expand the mask array length to 16 characters
  if (length > 11) return false;
  //11-character Event ID format: XXX-####-XXXX (3 letters, 4 numeric, 4 letters), 16-character Event ID format: XXX-****-****-*****  (3 letters, 4 alphanumeric, 4 alphanumeric, 5 alphanumeric)
  //so if userInput string index 7 - 10 is number, expand the mask array length to 16 characters
  if (length > 7) {
    for (let i = 7; i < length; i++) {
      let code = parseInt(userInputTrimmed[i], 10);
      if (!isNaN(code)) return false;
    }
  }
  //if userInput string index 3 - 6 is letter, expand the mask array length to 16 characters
  if (length > 3) {
    for (let i = 3; i < Math.min(length, 7); i++) {
      let code = parseInt(userInputTrimmed[i], 10);
      if (isNaN(code)) return false;
    }
  }
  //otherwise the mask array length should be 11 characters
  return true;
};

export const linkResults = async (
  testTakerId: string,
  email: any,
  token: string
) => {
  try {
    const formData = {
      email
    };
    const response = await fetch(
      `${talentInsightsURL}/teaming/requestLinkResults/${testTakerId}`,
      {
        method: "POST",
        headers: {
          Authorization: token
        },
        body: JSON.stringify(formData)
      }
    );
    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }
    return true;
  } catch (error) {
    console.log("error: ", error);
    return false;
  }
};

export const fetchLinkResults = async (token: string) => {
  try {
    const formData = {
      token
    };
    const response = await fetch(
      `${talentInsightsURL}/teaming/checkLinkResults`,
      {
        method: "POST",
        body: JSON.stringify(formData)
      }
    );
    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }
    const data = await response.json();
    return data.resultFound;
  } catch (error) {
    console.log("error: ", error);
    return false;
  }
};

export const doLinkResults = async (token: string) => {
  try {
    const formData = {
      token
    };
    const response = await fetch(`${talentInsightsURL}/teaming/linkResults`, {
      method: "POST",
      body: JSON.stringify(formData)
    });
    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.log("error: ", error);
    return false;
  }
};

const isTimeFactorAppliedTMTest = (testEventData: any, testId: any) => {
  return (
    testId.includes("tm_") &&
    (testEventData.tests[testId].details.timeQuestions ||
      testEventData.tests[testId].details.timeLimit) &&
    !testEventData.tests[testId].details.isTyping
  );
};

export const isTestTakerPhoneNumberValidated = (
  noPII: boolean,
  testTakerPhoneNumber: string | null
) => {
  // currently, we are not showing the phone number input in oda, so if a phone number fails validation, it is not
  // permitting the candidate to go forward, but also not displaying the error message or presenting a way for them to
  // correct it.  We are changing this to always return true as a stopgap measure to allow candidates to proceed without
  // the validation, and will revisit shortly to more comprehensively solve the issue.
  return true;
  // if noPII is true and testTakerPhoneNumber is empty, we don't need to validate the phone number
  // if (noPII || !testTakerPhoneNumber) {
  //   return true;
  // }

  // // will need to add a package once we have more countries to support
  // // works for US only for now
  // const cleanedPhoneNumber = testTakerPhoneNumber.replace(/[^\d+]/g, "");

  // const isValidPhoneNumber = /^\+[1-9]\d{10}$/.test(cleanedPhoneNumber);

  // return isValidPhoneNumber;
};

export const getTestTakerPhoneNumberValidationText = (
  noPII: boolean,
  testTakerPhoneNumber: string | null
) => {
  const validationText = isTestTakerPhoneNumberValidated(
    noPII,
    testTakerPhoneNumber
  )
    ? null
    : "Please enter a valid phone number";

  return validationText;
};

export const getPhoneNumberPlaceholderText = (
  countryCode: string,
  countryCodeAbbreviation: string
) => {
  if (countryCodeAbbreviation === "AU") {
    return `${countryCode} xxx-xxx-xxx`;
  }

  return `${countryCode} xxx-xxx-xxxx`;
};

export const eventDebugBreakpoints = {
  xl: { span: 8, offset: 2 },
  lg: { span: 8, offset: 2 },
  md: { span: 10, offset: 1 },
  sm: 12
};

export const getEventDebugHeader = () => (
  <Card.Header style={{ borderBottom: 0, backgroundColor: "#fff" }}>
    <Row>
      <Col>
        <Image
          src={logo}
          alt="On Demand Assessment"
          className="non-welcome-logo"
        />
      </Col>
    </Row>
  </Card.Header>
);
